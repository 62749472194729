import React, { useContext } from "react";

import IncidentReportForm from "../../../components/Admin/IncidentReports/IncidentReportForm/IncidentReportForm";
import AppContext from "../../../context/AppContext";

const ViewIncidentReport = () => {
  const appContext = useContext(AppContext);

  return <IncidentReportForm activeReport={appContext.activeReport} />;
};

export default ViewIncidentReport;
