import React, { useState, useContext } from "react";
import { Link, navigate } from "gatsby";
import { Formik, Form, Field } from "formik";
import moment from "moment";
import classNames from "classnames";

import {
  faClipboardList,
  faCommentAlt,
  faCheck,
  faTimes,
  faCheckDouble,
  faHospital,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import AppContext from "../../../../context/AppContext";
import Layout from "../../../../layout/Layout";
import SearchBar from "../../../Templates/Forms/SearchBar";
import Panel from "../../../Templates/Forms/Panel";
import Input from "../../../Templates/Forms/Input";
import Datalist from "../../../Templates/Forms/Datalist";
import TextArea from "../../../Templates/Forms/Textarea";

import api from "../../../../services/api";
import categories from "../utils/incidentCategories.json";
import { IncidentReportValidationSchema } from "../../../Templates/Forms/ValidationSchema";

const reports = ["IR", "ER"];
const reportSources = ["Client", "Clinic Staff"];

const reportTypeOptions = reports.map(report => ({
  value: report,
  label: report,
}));

const reportSourceOptions = reportSources.map(report => ({
  value: report,
  label: report,
}));

const categoryTypes = categories.map(category => ({
  value: category,
  label: category,
}));

const handleKeyDown = event => {
  if (event.key === "Enter") event.preventDefault();
};

const IncidentReportForm = ({ isLoading, activeReport = {} }) => {
  const [isActive, setActivebutton] = useState(
    activeReport.status || "To investigate"
  );
  const [reportType, setReportType] = useState(
    activeReport.reportType
      ? {
          value: activeReport.reportType,
          label: activeReport.reportType,
        }
      : ""
  );
  const [reportSource, setReportSource] = useState(
    activeReport.source
      ? {
          value: activeReport.source,
          label: activeReport.source,
        }
      : ""
  );
  const [primaryCategory, setPrimaryCategory] = useState(
    activeReport.primaryCategory
      ? {
          value: activeReport.primaryCategory,
          label: activeReport.primaryCategory,
        }
      : ""
  );
  const [secondaryCategory, setSecondaryCategory] = useState(
    activeReport.secondaryCategory
      ? {
          value: activeReport.secondaryCategory,
          label: activeReport.secondaryCategory,
        }
      : ""
  );
  const [clinicStaffList, addClinicStaff] = useState(
    activeReport.clinicStaffOnDuty || []
  );
  const [clinicStaffTags, addClinicStaffTag] = useState(
    activeReport.clinicStaffNames || []
  );

  const clinicStaffFields = ["firstName", "lastName", "staffType"];

  const appContext = useContext(AppContext);

  const handleAddClinicStaff = (result, setFieldValue) => {
    let tempClinicStaffList = [...clinicStaffList];
    let tempClinicStaffTags = [...clinicStaffTags];
    if (!tempClinicStaffList.includes(result.id)) {
      tempClinicStaffList.push(result.id);
      tempClinicStaffTags.push([
        `${result.firstName} ${result.lastName} (${result.staffType})`,
      ]);
    }
    addClinicStaff(tempClinicStaffList);
    addClinicStaffTag(tempClinicStaffTags);
    setFieldValue("clinicStaffOnDuty", tempClinicStaffList);
  };

  const handleRemoveClinicStaff = (index, setFieldValue) => {
    let tempClinicStaffList = [...clinicStaffList];
    let tempClinicStaffTags = [...clinicStaffTags];
    tempClinicStaffList.splice(index, 1);
    tempClinicStaffTags.splice(index, 1);
    addClinicStaff(tempClinicStaffList);
    addClinicStaffTag(tempClinicStaffTags);
    setFieldValue("clinicStaffOnDuty", tempClinicStaffList);
  };

  const reconstructValues = values => ({
    reportType: values.reportType,
    status: values.status,
    source: values.source,
    remarks: values.remarks || null,
    dateSentToMg: moment(values.dateSentToMg).format("YYYY-MM-DDTHH:mm+08:00"),
    clinic: values.clinic,
    complainant: values.complainant,
    primaryCategory: values.primaryCategory,
    secondaryCategory: values.secondaryCategory || null,
    complaintDateTime: moment(values.complaintDateTime).format(
      "YYYY-MM-DDTHH:mm+08:00"
    ),
    shortDescription: values.shortDescription || null,
    fullComplaint: values.fullComplaint,
    clinicStaffOnDuty: clinicStaffList,
    dateSentToChsi: values.dateSentToChsi
      ? moment(values.dateSentToChsi).format("YYYY-MM-DDTHH:mm+08:00")
      : null,
    dateChsiRepliedToMg: values.dateChsiRepliedToMg
      ? moment(values.dateChsiRepliedToMg).format("YYYY-MM-DDTHH:mm+08:00")
      : null,
    dateRepliedToTp: values.dateRepliedToTp
      ? moment(values.dateRepliedToTp).format("YYYY-MM-DDTHH:mm+08:00")
      : null,
    responseFromEmployee: values.responseFromEmployee || "",
    responseFromTp: values.responseFromTp || "",
  });

  const handleSubmit = values => {
    if (values.id)
      api
        .put(`/incident_reports/${values.id}/`, reconstructValues(values), {
          headers: {
            Authorization: `${sessionStorage.getItem("loginToken")}`,
          },
        })
        .then(() => {
          navigate("/admin/incident-reports/");
          appContext.useToast(
            "Successfully updated report.",
            "is-success",
            5000
          );
        })
        .catch(error => {
          appContext.useToast("Something went wrong.", "is-danger", 5000);
        });
    else
      api
        .post("/incident_reports/", reconstructValues(values), {
          headers: {
            Authorization: `${sessionStorage.getItem("loginToken")}`,
          },
        })
        .then(() => {
          navigate("/admin/incident-reports/");
          appContext.useToast("Successfully added report.", "is-success", 5000);
        })
        .catch(error => {
          appContext.useToast("Something went wrong.", "is-danger", 5000);
        });
  };

  return (
    <Layout
      pageTitle={`${
        Object.keys(activeReport).length > 0 ? "View" : "New"
      } Report`}
    >
      <Link to="/admin/incident-reports">
        <button type="button" className="button is-primary mb-2">
          <span className="icon">
            <FontAwesomeIcon icon={faArrowLeft} />
          </span>{" "}
          <span>Back</span>
        </button>
      </Link>
      <Formik
        initialValues={{
          id: activeReport.id || null,
          reportType: activeReport.reportType || "",
          status: activeReport.status || isActive,
          source: activeReport.source || "",
          remarks: activeReport.remarks || "",
          dateSentToMg:
            moment(activeReport.dateSentToMg).format("YYYY-MM-DDTHH:mm") ||
            moment().format("YYYY-MM-DDTHH:mm"),
          clinic: activeReport.clinic || "",
          complainant: activeReport.complainant || "",
          primaryCategory: activeReport.primaryCategory || "",
          secondaryCategory: activeReport.secondaryCategory || "",
          complaintDateTime:
            moment(activeReport.complaintDateTime).format("YYYY-MM-DDTHH:mm") ||
            moment().format("YYYY-MM-DDTHH:mm"),
          shortDescription: activeReport.shortDescription || "",
          fullComplaint: activeReport.fullComplaint || "",
          clinicStaffOnDuty: [...clinicStaffList],
          dateSentToChsi: activeReport.dateSentToChsi
            ? moment(activeReport.dateSentToChsi).format("YYYY-MM-DDTHH:mm")
            : null,
          dateChsiRepliedToMg: activeReport.dateChsiRepliedToMg
            ? moment(activeReport.dateChsiRepliedToMg).format(
                "YYYY-MM-DDTHH:mm"
              )
            : null,
          dateRepliedToTp: activeReport.dateRepliedToTp
            ? moment(activeReport.dateRepliedToTp).format("YYYY-MM-DDTHH:mm")
            : null,
          responseFromEmployee: activeReport.responseFromEmployee || "",
          responseFromTp: activeReport.responseFromTp || "",
          clinicName: activeReport.clinicName || "",
        }}
        validationSchema={IncidentReportValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, errors }) => {
          return (
            <Form onKeyDown={handleKeyDown} autoComplete="off">
              <Panel heading="Report Information" icon={faClipboardList}>
                <div className="columns">
                  <div className="column pb-0">
                    <Field name="reportType">
                      {({ field, form }) => (
                        <Datalist
                          defaultValue={reportType}
                          name="reportType"
                          placeholder="Select report type"
                          options={reportTypeOptions}
                          field={field}
                          hasLabel={true}
                          label="Report Type"
                          isRequired
                          onChange={selected => {
                            setReportType(selected);
                            form.setFieldValue("reportType", selected.value);
                          }}
                        />
                      )}
                    </Field>
                  </div>
                  <div className="column pb-0">
                    <Field name="source">
                      {({ field, form }) => (
                        <Datalist
                          defaultValue={reportSource}
                          name="source"
                          placeholder="Select complaint source"
                          options={reportSourceOptions}
                          field={field}
                          hasLabel={true}
                          label="Report Source"
                          isRequired
                          onChange={selected => {
                            setReportType(selected);
                            form.setFieldValue("source", selected.value);
                          }}
                        />
                      )}
                    </Field>
                  </div>
                  <div className="column pb-0">
                    <Input
                      name="dateSentToMg"
                      type="datetime-local"
                      label="Date Sent to MedGrocer"
                      isRequired
                    />
                  </div>
                  <div className="column pb-0">
                    <Input
                      name="complainant"
                      type="text"
                      label="Complainant"
                      placeholder="Complainant"
                      isRequired
                    />
                  </div>
                </div>

                <div className="columns">
                  <div className="column is-6 mb-1">
                    <Field name="primaryCategory">
                      {({ field, form }) => (
                        <Datalist
                          defaultValue={primaryCategory}
                          name="primaryCategory"
                          placeholder="Select from list below..."
                          options={categoryTypes}
                          field={field}
                          hasLabel={true}
                          label="Primary Category"
                          isRequired
                          onChange={selected => {
                            setPrimaryCategory(selected);
                            form.setFieldValue(
                              "primaryCategory",
                              selected.value
                            );
                          }}
                        />
                      )}
                    </Field>
                  </div>
                  <div className="column is-6 mb-1">
                    <Field name="secondaryCategory">
                      {({ field, form }) => (
                        <Datalist
                          defaultValue={secondaryCategory}
                          name="secondaryCategory"
                          placeholder="Select from list below..."
                          options={categoryTypes}
                          field={field}
                          hasLabel={true}
                          label="Secondary Category"
                          onChange={selected => {
                            setSecondaryCategory(selected);
                            form.setFieldValue(
                              "secondaryCategory",
                              selected.value
                            );
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </div>

                <div className="columns">
                  <div className="column is-6 pb-0">
                    <Input
                      name="complaintDateTime"
                      type="datetime-local"
                      label="Date and Time of Complaint"
                      isRequired
                    />
                  </div>
                  <div className="column is-6 pb-0">
                    <Input
                      name="shortDescription"
                      type="text"
                      label="Short Description"
                      placeholder="Short description"
                    />
                  </div>
                </div>

                <Field name="fullComplaint">
                  {({ field, form }) => (
                    <TextArea field={field} label="Full Complaint" isRequired />
                  )}
                </Field>
                <Input
                  name="remarks"
                  type="text"
                  label="Remarks"
                  placeholder="Remarks"
                />
              </Panel>

              <Panel heading="Clinic Information" icon={faHospital}>
                <div className="mb-3">
                  <Input
                    name="clinicName"
                    type="text"
                    label="Clinic Name"
                    placeholder="Clinic Name"
                    isRequired
                    render={({ field, form, props }) => {
                      return (
                        <SearchBar
                          endpoint="clinics"
                          resultDisplay={["name"]}
                          field={field}
                          form={form}
                          props={props}
                          isAutocomplete={true}
                          callbackFunction={result => {
                            setFieldValue("clinic", result.id);
                          }}
                        />
                      );
                    }}
                  />
                </div>

                <Input
                  name="clinicStaffName"
                  type="text"
                  label="Clinic Staff on Duty"
                  placeholder="Clinic Staff on Duty"
                  isRequired
                  render={({ field, form, props }) => {
                    return (
                      <SearchBar
                        endpoint="staff"
                        resultDisplay={clinicStaffFields}
                        field={field}
                        form={form}
                        props={props}
                        isAutocomplete={true}
                        callbackFunction={result => {
                          handleAddClinicStaff(result, setFieldValue);
                        }}
                      />
                    );
                  }}
                />
                <div className="field is-grouped is-multiline">
                  {clinicStaffTags.map((clinicStaff, index) => (
                    <div className="control">
                      <div className="tags has-addons">
                        <span className="tag">{clinicStaff}</span>
                        <a
                          className="tag is-delete"
                          onClick={() => {
                            handleRemoveClinicStaff(index, setFieldValue);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </Panel>

              <Panel heading="Response Information" icon={faCommentAlt}>
                <div className="columns">
                  <div className="column is-4 pb-0">
                    <Input
                      name="dateSentToChsi"
                      type="datetime-local"
                      label="Date Sent to Clinic Staff"
                    />
                  </div>
                  <div className="column is-4 pb-0">
                    <Input
                      name="dateChsiRepliedToMg"
                      type="datetime-local"
                      label="Date Clinic Staff Replied to MedGrocer"
                    />
                  </div>
                  <div className="column is-4 pb-0">
                    <Input
                      name="dateRepliedToTp"
                      type="datetime-local"
                      label="Date Replied to Client"
                    />
                  </div>
                </div>
                <Field name="responseFromEmployee">
                  {({ field, form }) => (
                    <TextArea field={field} label="Response From Employee" />
                  )}
                </Field>
                <Field name="responseFromTp">
                  {({ field, form }) => (
                    <TextArea field={field} label="Response From Client" />
                  )}
                </Field>
              </Panel>

              <Panel heading="Status" icon={faCheckDouble}>
                <div className="columns">
                  <div className="column pb-0 is-6">
                    <div className="field has-addons">
                      <button
                        className={classNames("button", {
                          "is-primary": isActive === "To investigate",
                        })}
                        type="button"
                        onClick={() => {
                          setActivebutton("To investigate");
                          setFieldValue("status", "To investigate");
                        }}
                      >
                        To investigate
                      </button>
                      <button
                        className={classNames("button", {
                          "is-primary": isActive === "To draft letter",
                        })}
                        type="button"
                        onClick={() => {
                          setActivebutton("To draft letter");
                          setFieldValue("status", "To draft letter");
                        }}
                      >
                        To draft letter
                      </button>
                      <button
                        className={classNames("button", {
                          "is-primary": isActive === "Ok",
                        })}
                        type="button"
                        onClick={() => {
                          setActivebutton("Ok");
                          setFieldValue("status", "Ok");
                        }}
                      >
                        Ok
                      </button>
                      <button
                        className={classNames("button", {
                          "is-primary": isActive === "Other",
                        })}
                        type="button"
                        onClick={() => {
                          setActivebutton("Other");
                          setFieldValue("status", values.other);
                        }}
                      >
                        Other
                      </button>
                    </div>
                    {isActive === "Other" && (
                      <div className="columns">
                        <div className="column is-6 mt-1">
                          <Input
                            name="other"
                            type="text"
                            label="Other"
                            placeholder="Other"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Panel>
              <div className="buttons is-pulled-right pb-5">
                <button type="button" className="button is-light">
                  <span className="icon">
                    <FontAwesomeIcon icon={faTimes} />
                  </span>
                  <Link to="/incident-reports">Cancel</Link>
                </button>
                <button
                  type="submit"
                  className={classNames("button is-primary", {
                    "is-loading": isLoading,
                  })}
                >
                  <span className="icon">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  <span>Save</span>
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Layout>
  );
};

export default IncidentReportForm;
